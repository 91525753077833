import { Input, Label } from '@/components/basic';
import { ConfirmDialog } from '@/components/basic/ConfirmDialog/ConfirmDialog';
import { api } from '@/lib/api/api';
import { ISchedule } from '@gettactic/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import toast from 'react-hot-toast';
import { useAuthenticated } from '@gettactic/hooks';

export interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  schedules: ISchedule[];
}

type DeleteRecurringOptions = 'this' | 'future' | 'all';

export function ReservationCancel({ setOpen, open, schedules }: Props) {
  const isSingle = schedules.length === 1;
  const queryClient = useQueryClient();
  const [recurringValue, setRecurringValue] =
    useState<DeleteRecurringOptions>('this');
  const onClose = useCallback(() => {
    setRecurringValue('this');
    setOpen(false);
  }, []);
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const deleteMutation = useMutation(
    async () => {
      const withoutDuplicates = schedules.filter((schedule, index, self) => {
        return index === self.findIndex((s) => s.id === schedule.id);
      });
      const promises = withoutDuplicates.map((schedule) => {
        const res = api.client.schedules.delete({
          schedule: recurringValue === 'all' && schedule.recurring_schedule_id ? schedule.recurring_schedule_id : schedule.id,
          recurring: isRecurring && recurringValue === 'future',
          slug: authenticatedUser.slug ?? '',
          resourceId: schedule.resource?.id ?? ''
        });
        return res;
      });
      return await Promise.all(promises);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['schedules']);
        queryClient.invalidateQueries(['organizations.schedulable']);
        queryClient.invalidateQueries(['organizations.questionnaire']);
        onClose();
        toast.success('Scheduled deleted successfully');
      }
    }
  );
  const isRecurring = schedules.length === 1 && !!schedules[0].recurring_task;
  const options: { label: string; value: DeleteRecurringOptions }[] = useMemo(
    () => [
      { label: 'Only this reservation', value: 'this' },
      { label: 'This and all the future reservations', value: 'future' },
      { label: 'All the reservations', value: 'all' }
    ],
    []
  );

  return (
    <ConfirmDialog
      title={
        !isRecurring ? 'Confirm Cancellation' : 'Confirm Recurring Cancellation'
      }
      open={open}
      onConfirm={deleteMutation.mutate}
      loading={deleteMutation.status === 'loading'}
      onClose={onClose}
      type="cancel"
      confirmText="Yes"
      cancelText="No"
      noFlex
    >
      {!isRecurring ? (
        <p className="text-tertiary">
          {isSingle
            ? 'Are you sure you would like to cancel this reservation?'
            : `Are you sure you would like to cancel these ${schedules.length} reservations?`}{' '}
        </p>
      ) : (
        <div className="mt-2 flex flex-col text-sm">
          {options.map((option) => (
            <Label key={option.value} radio className="my-1 block">
              <Input
                type="radio"
                onChange={() => {
                  setRecurringValue(option.value);
                }}
                checked={option.value === recurringValue}
              />
              <span className="ml-2">{option.label}</span>
            </Label>
          ))}
        </div>
      )}
    </ConfirmDialog>
  );
}
